<template>
  <b-nav-item
    v-if="wareHouse.id"
  >
    <b-dropdown
      size="md"
      right
      no-caret
      variant="customClassButton"
      class="paddingCustom"
    >
      <template #button-content>
        <div class="dropNewClass header__nav-item d-inline-flex">
          <span class="header__nav-item-icon d-inline-block">
            <feather-icon
              icon="LWarehouseIcon"
              :fill="themeColor ? themeColor : ''"
              :size="showText ? '24' : '18'"
            />
          </span>
          <span
            v-if="loader"
            style="margin-bottom: 5px"
          >
            <b-spinner
              small
              variant="secondary"
              type="grow"
            />
          </span>
          <span
            v-else
            :class="showText ? '': 'd-none p-0'"
          >
            {{ wareHouse.label }}
          </span>
        </div>
      </template>
      <div class="dropdownItem">
        <div
          v-for="(item,index) in filteredWarehouses(wareHouse.id, getNewestWarehousesList.length > 0 ? getNewestWarehousesList : warehousesList)"
          :key="item.id"
          :data-test="`navbar-warehouse-item-${index + 1}`"
        >
          <b-dropdown-item

            class="dropdownItem mt-0"
            @click="showConfirmationModalWarehouse(item.id)"
          >
            <div class="header__nav-item dropdownText">
              <span class="d-flex align-items-center header__nav-item-icon d-inline-block">
                <div v-if="checkPermissions(wareHousePermissions, 'change')">
                  <feather-icon
                    icon="LWarehouseIcon"
                    size="18"
                    :fill="themeColor ? themeColor : ''"
                    style="margin-right: 5px"
                  />
                </div>
                <div v-else-if="checkPermissions(wareHousePermissions, 'view')">
                  <feather-icon
                    icon="LEyeIcon"
                    size="18"
                    :fill="themeColor ? themeColor : ''"
                    style="margin-right: 5px"
                  />
                </div>
                <div v-else>
                  <feather-icon
                    icon="LStopIcon"
                    size="18"
                    :fill="themeColor ? themeColor : ''"
                    style="margin-right: 5px"
                  />
                </div>
                <div style="margin-top: 3px">
                  {{ $t(item.label) }}
                </div>
              </span>
              <span />
            </div>
          </b-dropdown-item>
        </div>
      </div>
    </b-dropdown>
    <b-modal
      id="bs-warning-modal-warehouse"
      ref="bs-warning-modal-warehouse"
      centered
      hide-header
      body-class="bs-warning-modal-warehouse__body px-2 py-2"
      footer-class="bs-warning-modal-warehouse__footer justify-content-between"
      ok-variant="success"
      ok-title="Discard"
      :cancel-title="$t('cancel')"
      modal-class="bs-warning-modal-warehouse"
      @cancel="hideTheModal"
      @ok="handleOk"
      @hide="hideTheModal"
    >
      <div class="text-center py-2">
        <feather-icon
          icon="LWarningIcon"
          size="44"
          class="mb-2"
        />
        <h4 class="font-weight-bolder">
          {{ $t('Are you sure you want to discard changes?') }}
        </h4>
      </div>
    </b-modal>
  </b-nav-item>
</template>
<script>
import {
  BNavItem, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItem,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  props: {
    showText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      getNewestWarehousesList: [],
      adminRoleName: 'admin',
      loader: false,
      warehouseId: 0,
      themeColor: localStorage.getItem('storageThemeColor'),
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    wareHouse() {
      return this.$store.state.app.warehouse
    },
    wareHousePermissions() {
      return this.$store.state.app.warehousePermissions
    },
    warehousesList() {
      return this.$store.state.horizontalMenu.warehouseLocations
    },
  },
  watch: {
    warehousesList(val) {
      if (!val.length) return
      this.getNewestWarehousesList = val
    },
  },
  created() {
    this.$emit('updateMenu', 'current-warehouse')
  },
  methods: {
    showConfirmationModalWarehouse(itemId) {
      if (useJwt.getFormChangesOnForm() === 'true') {
        this.warehouseId = itemId // Store warehouseId in data
        this.$bvModal.show('bs-warning-modal-warehouse')
      } else if (this.checkForTransferOrder(itemId)) {
        this.$bvToast.toast(this.$t('There is no such items/assets in this warehouse, impossible to create/complete a Transfer Order from it'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
      } else {
        this.changeWarehouse(itemId)
      }
    },
    handleOk() {
      this.hideTheModal()
      this.changeWarehouse(this.warehouseId)
    },
    hideTheModal() {
      this.$bvModal.hide('bs-warning-modal-warehouse')
    },
    filteredWarehouses(warehouseId, warehouseList) {
      if (warehouseList.length === 0) {
        return []
      }
      return warehouseList?.filter(warehouse => warehouse.id !== warehouseId && warehouse.status)
    },
    checkPermissions(permissionsArr, label) {
      if (!permissionsArr.length) return false
      return permissionsArr.find(item => item.action === label)
    },
    loadList() {
      this.loader = true
      this.$store.dispatch('horizontalMenu/getWarehousesList').finally(() => {
        this.loader = false
      })
    },
    checkForTransferOrder(itemID) {
      // This function gives access to change the Warehouse during transfer order view
      const allowedWarehouses = this.$store.state?.orders?.allowedWarehouses
      // eslint-disable-next-line no-prototype-builtins
      if (!allowedWarehouses || !allowedWarehouses.hasOwnProperty('warehouseFrom')) {
        return false
      }
      const warehouseFrom = allowedWarehouses.warehouseFrom?.id
      const warehouseTo = allowedWarehouses.warehouseTo?.id
      return (itemID !== warehouseTo && itemID !== warehouseFrom)
    },
    changeWarehouse(id) {
      if ((this.wareHousePermissions.length > 0)) {
        this.loader = true
        this.$store.dispatch('horizontalMenu/updateWarehouseLocation', { warehouse_id: String(id) }).then(res => {
          const { data } = res
          this.$store.commit('horizontalMenu/WAREHOUSE_LOCATION', data)
          this.loader = false
        }).catch(err => {
          this.$bvToast.toast(this.$t('This warehouse is invalid, it is trashed or deleted'), {
            variant: 'danger',
            solid: true,
            title: this.$t('Error'),
          })
          this.loadList()
          console.log(err)
          this.loader = false
        }).finally(() => {
          this.loader = false
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        })
      } else {
        this.$bvToast.toast(this.$t('This warehouse cannot be selected'), {
          variant: 'warning',
          solid: true,
          title: this.$t('Notification'),
        })
      }
    },
  },
  setup() {
    const MODULE_NAME = 'warehouseLocation'
    return {
      MODULE_NAME,
    }
  },
}
</script>
<style scoped>
.show >>> .dropdown-menu {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.dropdownItem {
  background-color: #171e27 ;
  box-shadow: none;
}
.dropdownText {
  color: #fff;
  cursor: pointer;
  transition: color ease-in-out 0.2s;
}
.dropdownText:hover {
  color: #22e0e0;
}
.dropNewClass{
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2px;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-size: 16px !important;
  color: #f3f3f3 !important;
}
.btn-customClassButton{
  padding: 0.62rem 1rem !important;
}
.paddingCustom{
  border: none;
  margin-right: 9px;
}
</style>
